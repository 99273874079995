import axios from "axios";
// import { Loading } from "element-plus";
let loadingRequestCount = 0;

const baseURL = process.env.NODE_ENV === 'development' ? "https://project-test-api.benq.com.cn/design" : "https://designmonitor.benq.com.cn/design/"
const service = axios.create({
  baseURL: baseURL,
  timeout: 1200000, // 请求超时时间
});

//请求拦截
service.interceptors.request.use(
  (config) => {
    // console.log(config);
    if (getTokenInfo()) {
      let token = getTokenInfo();
      config.headers.Authorization = token.tokenHead + token.token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//响应拦截
service.interceptors.response.use(
  (res) => {
    setTimeout(() => {
      hideLoading();
    }, 200);
    return res;
  },
  // 请求失败情况
  (error) => {
    setTimeout(() => {
      hideLoading();
    }, 200);
    const { response } = error;
    console.log(response);
    if (response) {
      // 若状态码为 510 或者 401， 跳转至登录界面
      if (
        response.status === 510 ||
        response.status === 401 ||
        response.status === 512
      ) {
        localStorage.token = "";
        location.href = "/signUp";
        console.log("服务故障，请检查！");
      }
      return Promise.reject(response);
    } else {
      console.log(error);
      if (error.message) {
        console.log(error.message);
      } else {
        console.log("服务故障，请检查！");
      }
      return Promise.resolve();
    }
  }
);

let loadingInstance = "";
// const showLoading = () => {
//   if (loadingRequestCount === 0) {
//     loadingInstance = Loading.service({
//       target: ".loading-area",
//     });
//   }
//   loadingRequestCount++;
// };
const hideLoading = () => {
  if (loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if (loadingRequestCount === 0) {
    loadingInstance.close();
  }
};
function getTokenInfo() {
  // let endTime = new Date().getTime()
  if (localStorage.token) {
    return JSON.parse(localStorage.token);
  } else {
    return false;
  }
}
export default service;
